
function initNavigationBarre() {
    var st = $(this).scrollTop();
    if (st > 100) {
        $(".barre-navigationTransparent").removeClass("barre-navigationTransparentStyle");
    }
    else {
        $(".barre-navigationTransparent").addClass("barre-navigationTransparentStyle");
    }

}

/* Detect the scroll of the page and animate the menu */
$(window).on('scroll', function () {
    initNavigationBarre();
});



$( document ).ready(function() {
    initNavigationBarre();
});