// Languages selector
$('.lang').addClass('is-display');
$('.lang-dropdown > a').on("click", function (e) {
    e.preventDefault();
    $(this).next().toggleClass("open");
});


if (isIE()) {
    document.getElementsByTagName('body')[0].className += ' ie';
}

if (isNoHover()) {
    document.getElementsByTagName('body')[0].className += ' no-hover';
}

th_accordeon.init();
th_overlay.init();
th_expand.parse();
thVheight.init();

// Lancement du script Librairie Zoombox
$('.zoombox').zoombox();


// affichage des overlays possible que a partie de 2s
setTimeout(function () {
    $('body').addClass('overlay-load');
}, 1000);

// Lazyload
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});


/* Detect the scroll of the page and animate the menu */
$(window).on('scroll', function (e) {
    var st = $(this).scrollTop();

    if (st > 100) {
        $("body").addClass("is-scrolled");
    }
    else {
        $("body").removeClass("is-scrolled");
    }
});

var lastscrolltop = 0;
var lastIsDirTop = 0;
document.addEventListener('scroll',function(){
    var st = $(document).scrollTop();
    if(st<lastscrolltop && lastIsDirTop == 0){
        lastIsDirTop = 1;
        $(document.body).addClass('scrolldir-top',true);
    }
    if(st>lastscrolltop && lastIsDirTop == 1){
        lastIsDirTop = 0;
        $(document.body).removeClass('scrolldir-top',true);
    }
    lastscrolltop = st;
});

$('a figcaption').on('touchstart click', function (e) {
    e.stopPropagation();
    e.preventDefault();
});