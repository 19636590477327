(function(){

    /*
        ! ATTENTION !
        Ce slider est présent une seul fois dans la page
        Ce code n'est pas utilisable pour du bloc
    */

    var sliderEl = document.querySelector('.cover-home .th-slider');

    if(sliderEl){

        var navContainerEl = document.querySelector('.cover-home .nav-buttons');
        var dotsContainerEl = document.querySelector('.cover-home .nav-dots');


        var s = new thSlider(sliderEl, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 1024,

            oninit: function (slider) {
                if (navContainerEl) {
                    thSliderTools.onInitNav(slider, navContainerEl.children);
                }
                if (dotsContainerEl) {
                    thSliderTools.onInitDots(slider, dotsContainerEl);
                }
            },
            onchange: function (slider) {
                if(navContainerEl){
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navContainerEl.children);
                }
                if (dotsContainerEl) {
                    thSliderTools.onChangeDots(slider, dotsContainerEl);
                }
            }
        });

    }

})();
