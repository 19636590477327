//On attend que l'event d'ouverture du paneau de confg se lance pour faire les actions
window.addEventListener('tac.open_panel',function(){
    // On duplique le bouton de fermeture du panel dans la partie fixed, seulement si il n'a pas déjà été dupliqué
    if($('#tarteaucitronScrollbarAdjust #tarteaucitronClosePanel').length == 0){
        $('#tarteaucitronClosePanel').appendTo('#tarteaucitronScrollbarAdjust'); //Pas besoins de le cloner étant donné qu'on a pas besoins de la croix
    }


    //Forcer le close panel au click du nouveau bouton dupliqué
    $('#tarteaucitronClosePanel, #tarteaucitronAllAllowed, #tarteaucitronAllDenied', '#tarteaucitronScrollbarAdjust').on('click',function(){
        tarteaucitron.userInterface.closePanel();
    });


    //A l'ouverture du paneau de config on met toutes les valeurs à FAUX, seulement si on a au moins une valeur à wait
    var tacCookie = false;

    var name = "tarteaucitron=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            tacCookie = c.substring(name.length, c.length);
        }
    }

    //On récupère la valeur du cookie tarte au citron et on le découpe pour avoir chaque service
    var cookiesStatus = tacCookie.split("!");
    cookiesStatus.splice(0, 1);
    //Pour chaque service on vérifie si il est à wait. Si il l'est on passe tout à false (car ça veux dire qu'on a pas encore fais de choix, et par default on choisis tout à faux)
    $.each(cookiesStatus, function(){
        var c = this.split("=");
        if(c[1] == "wait"){
            tarteaucitron.userInterface.respondAll(false);
        }
    });
});




tarteaucitron.init({
    "privacyUrl": "", /* Privacy policy url */
    "hashtag": "#rgpd", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */
    "orientation": "bottom", /* Banner position (middle or bottom) */
    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */
    "adblocker": false, /* Show a Warning if an adblocker is detected */
    "DenyAllCta": true, /* Show the deny all button */
    "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
    "removeCredit": true, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
    "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */
    //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
    "readmoreLink": "", /* Change the default readmore link */
    "mandatory": true, /* Show a message about mandatory cookies */
});

// analytics
tarteaucitron.user.gtagUa = '';
tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
(tarteaucitron.job = tarteaucitron.job || []).push('gtag');

// tarteaucitron.user.facebookpixelId = 'YOUR-ID'; tarteaucitron.user.facebookpixelMore = function () { /* add here your optionnal facebook pixel function */ };
// (tarteaucitron.job = tarteaucitron.job || []).push('facebookpixel');


function CitronVideoHtml(id, plateforme, autoplay, mute) {
    return '<div class="tac_player ' + plateforme + '_player" videoID="' + id + '" width="1280px" theme="dark" height="auto" showinfo="0" controls="0" rel="0" autoplay=' + autoplay + '" mute="' + mute + '"></div>';
}